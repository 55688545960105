import React, { FC } from 'react'

const Upload: FC<DefaultPropsIcon> = ({ color = '#6E7191', size = 24, className = '' }) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75 12L11.25 12L11.25 7.5L13.52 7.5C13.69 7.5 13.82 7.36 13.82 7.19C13.82 7.12 13.79 7.04 13.73 6.98L9.21 2.46C9.09 2.34 8.9 2.34 8.78 2.46L4.26 6.98C4.14 7.1 4.14 7.29 4.26 7.41C4.31 7.46 4.39 7.5 4.47 7.5L6.75 7.5L6.75 12ZM4.5 13.5L13.5 13.5C13.91 13.5 14.25 13.83 14.25 14.25C14.25 14.66 13.91 15 13.5 15L4.5 15C4.08 15 3.75 14.66 3.75 14.25C3.75 13.83 4.08 13.5 4.5 13.5Z"
        fill={color}
      />
    </svg>
  )
}

export default Upload
