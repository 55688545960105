import React, { FC } from 'react'

const Star: FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#818C99' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.34 8.48L19.77 8.9C21.31 9.05 21.78 10.56 20.59 11.55L17.12 14.43L18.41 19.12C18.83 20.67 17.52 21.6 16.21 20.67L12 17.7L7.78 20.67C6.47 21.6 5.16 20.67 5.58 19.12L6.87 14.43L3.4 11.55C2.2 10.55 2.67 9.05 4.22 8.9L8.65 8.48L10.6 3.98C11.21 2.57 12.78 2.57 13.39 3.98L15.34 8.48Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default Star
