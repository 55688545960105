import React from 'react'

const Attention: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 50, onClick, className, color = '#EAAC30' } = props
  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M24.99 11.63C25.46 11.63 25.9 11.81 26.23 12.14C26.55 12.46 26.74 12.91 26.74 13.37L26.74 27.32C26.74 
          27.79 26.55 28.23 26.23 28.56C25.9 28.88 25.46 29.07 24.99 29.07C24.53 29.07 24.09 28.88 23.76 28.56C23.43 
          28.23 23.25 27.79 23.25 27.32L23.25 13.37C23.25 12.91 23.43 12.46 23.76 12.14C24.09 11.81 24.53 11.63 24.99 
          11.63ZM24.99 36.63C25.61 36.63 26.2 36.38 26.64 35.94C27.07 35.51 27.32 34.92 27.32 34.3C27.32 33.68 27.07 33.09 
          26.64 32.66C26.2 32.22 25.61 31.98 24.99 31.98C24.38 31.98 23.79 32.22 23.35 32.66C22.91 33.09 22.67 33.68 22.67 
          34.3C22.67 34.92 22.91 35.51 23.35 35.94C23.79 36.38 24.38 36.63 24.99 36.63Z"
          fill={color}
        />
        <path
          d="M0 25C0 11.19 11.19 0 25 0C38.8 0 50 11.19 50 25C50 38.8 38.8 50 25 50C11.19 50 0 38.8 0 25ZM25 3.48C19.29 3.48 
          13.82 5.75 9.78 9.78C5.75 13.82 3.48 19.29 3.48 25C3.48 30.7 5.75 36.17 9.78 40.21C13.82 44.24 19.29 46.51 25 46.51C30.7 
          46.51 36.17 44.24 40.21 40.21C44.24 36.17 46.51 30.7 46.51 25C46.51 19.29 44.24 13.82 40.21 9.78C36.17 5.75 30.7 3.48 
          25 3.48Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default Attention
