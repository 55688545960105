import { createSlice } from '@reduxjs/toolkit'

interface OpenRatingModule {
  id: string | number
  title: string
}

const initialState = {}

const ratingSlice = createSlice({
  name: 'rating',
  initialState: initialState,
  reducers: {},
})

export default ratingSlice.reducer

export const ratingSliceActions = {
  ...ratingSlice.actions,
}
