import {
  QUESTION_VIEW,
  SURVEY,
  SURVEYS_QUESTIONS,
  SURVEYS_RESULTS,
  SURVEYS_RESULTS_DETAIL,
  SURVEYS_VIEW,
} from './routes'

export const ICO_MENU_PATHS = [
  SURVEYS_QUESTIONS,
  SURVEYS_RESULTS_DETAIL,
  SURVEYS_RESULTS,
  SURVEY,
  QUESTION_VIEW,
  SURVEYS_VIEW,
]
