import React, { FC } from 'react'

interface Props {
  size?: number | string
  className?: string
  onClick?: () => void
  color?: string
}
const More: FC<Props> = (props) => {
  const { size = '20px', className = '', color = '#7F7F7F', ...rest } = props
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <ellipse cx="12" cy="12" rx="2" ry="2" transform="rotate(-90 12 12)" fill={color} />
      <ellipse cx="12" cy="5" rx="2" ry="2" transform="rotate(-90 12 5)" fill={color} />
      <ellipse cx="12" cy="19" rx="2" ry="2" transform="rotate(-90 12 19)" fill={color} />
    </svg>
  )
}

export default More
