import React from 'react'

const Warning: React.FC<DefaultPropsIcon> = (props) => {
  const { size = 24, onClick, className, color = '#EAAC30' } = props

  return (
    <span className={['anticon', 'anticon-setting', className || ''].join(' ')} onClick={onClick}>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 2C6.48 2 2 6.48 2 12C2 17.51 6.48 22 12 22C17.51 22 22 17.51 22 12C22 6.48 17.51 2 12 2ZM11 
            16L11 12C11 11.44 11.44 11 12 11C12.55 11 13 11.44 13 12L13 16C13 16.55 12.55 17 12 17C11.44 17 11 
            16.55 11 16ZM10.75 8.25L10.75 7.75C10.75 7.19 11.19 6.75 11.75 6.75L12.25 6.75C12.8 6.75 13.25 7.19 
            13.25 7.75L13.25 8.25C13.25 8.8 12.8 9.25 12.25 9.25L11.75 9.25C11.19 9.25 10.75 8.8 10.75 8.25Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default Warning
