import React, { FC } from 'react'

interface IArrowRightBreadcrumb {
  className?: string
  color?: string
  size?: string
}

const ArrowRightBreadcrumb: FC<IArrowRightBreadcrumb> = ({ size = '24', className, color = '#B8C1CC' }) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11 17L15 12L11 7" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default ArrowRightBreadcrumb
