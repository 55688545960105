import React from 'react'
import styles from './survey-footer.module.scss'
import { Col, Row, Typography } from 'antd'
import { Link } from 'react-router-dom'

const SurveyFooter = () => {
  return (
    <div className={styles.container}>
      <Row align={'middle'} justify={'start'} wrap={false} style={{ height: '100%' }}>
        <Col className={styles.menuColF}>
          <Link to={'/terms-of-service'}>Пользовательское соглашение</Link>
        </Col>

        <Col className={styles.menuCol}>
          <Link to={'/confidentiality-policy'}>Политика в отношении обработки персональных данных</Link>
        </Col>
        <Col className={styles.copy}>
          <Typography>© {new Date().getFullYear()} РОСЭНЕРГОАТОМ КОНСИСТ-ОС</Typography>
        </Col>
      </Row>
    </div>
  )
}

export default SurveyFooter
